import React from 'react';
import {
  Image,
  Platform,
  Text,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
  WebView,
  Linking,
  Dimensions,
  ActivityIndicator
} from 'react-native';

import { OriyaText } from '../components/StyledText';
import SecondaryHeader from '../components/SecondaryHeader';
import { HR } from '../components/HR';
import Pulse from '../components/Pulse';
import CustomImage from '../components/CustomImage';
import Button from '../components/Button';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import Storage from '@aws-amplify/storage';

export default class ResourceDetailScreen extends React.Component {
  /*
  TODO: optimize so that instead of re-rendering a single resource screen every
  time a new resource is selected, you pre-mount a component for every resource
  and just index into the list of resource components
  */
  constructor(props) {
    super(props);
    this.state = {
      resource: this.props.resource,
      resourceName: this.props.resource.name,
      details: this.props.resource.details,
      images: {},
      numDetails: this.props.resource.details.length
      // imagesLoaded: false
    }
    this.renderDetails = this.renderDetails.bind(this);
    this.disabled = true;
  }

  componentDidMount() {
    let images = [];
    this.state.resource.details.map((detail, index) => {
      if (detail.images) {
        images.push(detail.images[0]);
      }
    })
    this._loadImages(images);
    setTimeout(
      function() {
        this.disabled = false;
      }
      .bind(this),
      250
    );
  }

  _loadImages(images) {
    images.map(async (image, index) => {
      const src = await Storage.get(image.uri);
      if (src) {
        let newImages = Object.assign({}, this.state.images);
        newImages[image.uri] = src;
        this.setState({images: newImages});
      }
    })
    // this.setState({imagesLoaded: true})
  }

  renderDetails(detail, index) {
    const { numDetails } = this.state;
    if (detail.images) {
      try {
        let imageUri = this.state.images[detail.images[0].uri];
        let imageCaption = detail.images[0].caption || '';
        return (
          <View key={index}>
            <ScrollView minimumZoomScale={1} maximumZoomScale={5} contentContainerStyle={{alignItems: 'center', width: '100%'}}>
              <CustomImage imageUri={imageUri} imageCaption={imageCaption}/>
            </ScrollView>
            { (index < numDetails - 1) ? <HR /> : null }
          </View>
        )
      } catch (err) {
        return null;
      }
    } else if (detail.links) {
      let link = detail.links[0].url;
      if (link.indexOf('http://') == -1 && link.indexOf('https://') == -1) {
        link = 'https://' + link;
      }
      return (
        <View key={index}>
          <Text style={[styles.detailText, styles.link]} onPress={() => {
            if (this.disabled) { return; }
            else {
              Linking.openURL(link).catch(err => console.error('An error occurred', err));
            }
          }}>
            {detail.links[0].url}
          </Text>
          { (index < numDetails - 1) ? <HR /> : null }
        </View>
      )
    } else if (detail.html) {
      return (
        <View key={index}>
          <div className="html" dangerouslySetInnerHTML={{ __html: detail.html }}></div>
          { (index < numDetails - 1) ? <HR /> : null }
        </View>
      )
    } else {
      return (
        <View key={index}>
          <Text style={styles.detailName}>{detail.displayName}</Text>
          <Text style={styles.detailText}>{detail.value}</Text>
          { (index < numDetails - 1) ? <HR /> : null }
        </View>
      )
    }
  }

  render() {
    if (this.props.hidden) {
      return null;
    }
    return (
      <View style={styles.container}>
        <SecondaryHeader>{this.state.resourceName}</SecondaryHeader>
        <ScrollView style={styles.scrollView}>
        {
          this.state.details.map(this.renderDetails)
        }
        </ScrollView>
      </View>
    );
  }
}

const win = Dimensions.get('window');

const styles = StyleSheet.create({
  detailName: {
    fontSize: Layout.h3Text,
    color: Colors.defaultTextDarker
  },
  container: {
    flex: 1,
    marginBottom: 20
  },
  detailText: {
    fontSize: Layout.pText,
    color: Colors.defaultText
  },
  scrollView: {
    paddingTop: Layout.contentPaddingTop
  },
  link: {
    textDecorationLine: 'underline',
    color: Colors.electricblue
  }
});
