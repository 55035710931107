import React from 'react';
import {
  TouchableOpacity,
  View,
} from 'react-native';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
// import { Icon } from '../components/Icon';
import MdQrScanner from 'react-ionicons/lib/MdQrScanner'
import { OriyaText } from '../components/StyledText';

export default class QR extends React.Component {
  render() {
    return (
      <TouchableOpacity style={style} onPress={this.props.onPress}>
        <MdQrScanner color={Colors.defaultTextDarker} fontSize="1.7em" />
      </TouchableOpacity>
    )
  }
}

const style = {
  backgroundColor: Colors.button,
  borderRadius: '10em',
  padding: '.7em',
  height: '100%',
  marginHorizontal: '1em',
  alignItems: 'center',
  justifyContent: 'center'
}
