import React from 'react';
import {
  StyleSheet,
  View,
  Text,
  KeyboardAvoidingView,
  TextInput
} from 'react-native';
import { OriyaText } from '../components/StyledText';
import Button from '../components/Button';
import TopHeader from '../components/TopHeader';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import { RequireNewPassword } from 'aws-amplify-react';
import { I18n } from '@aws-amplify/core';
// AWS Amplify modular import
import Auth from '@aws-amplify/auth'

export default class CustomRequireNewPassword extends RequireNewPassword {
  constructor(props) {
    super(props);

    this._validAuthStates = ['requireNewPassword'];
    this.state = {
      error: '',
      password: ''
    }
  }

  change() {
    const user = this.props.authData;
    const { password } = this.state;
    const { requiredAttributes } = user.challengeParam;
    const attrs = objectWithProperties(this.inputs, requiredAttributes);

    if (!Auth || typeof Auth.completeNewPassword !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }
    Auth.completeNewPassword(user, password, attrs)
      .then(user => {
        if (user.challengeName === 'SMS_MFA') {
            super.changeState('confirmSignIn', user);
        } else if (user.challengeName === 'MFA_SETUP') {
            super.changeState('TOTPSetup', user);
        } else {
            super.checkContact(user);
        }
      })
      .catch(err => super.error(err));
    }

  showComponent(theme) {
    const user = this.props.authData;
    const { requiredAttributes } = user.challengeParam;

    return (
      <KeyboardAvoidingView style={styles.container} behavior='padding' enabled>
        <View style={styles.container}>
          <View>
            <TopHeader>
              Change Password
            </TopHeader>
            <OriyaText style={styles.requirementsHeader}>Password must contain:</OriyaText>
            <OriyaText style={styles.requirements}>8 to 15 characters</OriyaText>
            <OriyaText style={styles.requirements}>At least one lowercase letter</OriyaText>
            <OriyaText style={styles.requirements}>At least one uppercase letter</OriyaText>
            <OriyaText style={styles.requirements}>At least one numeric digit</OriyaText>
            <OriyaText style={styles.requirements}>At least one special character</OriyaText>
            {
              this.state.error ?
              (
                <View>
                  <Text style={styles.error}>{this.state.error}</Text>
                </View>
              ) : null
            }
          </View>
          <View style={styles.contentContainer}>
            <View style={styles.itemStyle}>
              <TextInput
                style={styles.input}
                autoFocus
                placeholder={I18n.get('New Password')}
                theme={theme}
                key="password"
                name="password"
                type="password"
                secureTextEntry={true}
                onChangeText={(text) => {this.setState({ password: text })}}
              />
            </View>
            <Button
              style={styles.buttonStyle}
              text='Change'
              onPress={() => {console.log('change'); this.change()}}
            />
            <Button style={styles.buttonStyle} text='Back to Sign In' onPress={() => this.changeState('signIn')}/>
          </View>
        </View>
      </KeyboardAvoidingView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: '2.5em',
    left: 10,
    right: 10,
    flex: 1,
    backgroundColor: '#ffffff',
    justifyContent: 'flex-start',
    flexDirection: 'column'
  },
  contentContainer: {
    marginTop: Layout.contentPaddingTopL
  },
  input: {
    fontSize: Layout.pText,
    color: Colors.defaultText,
	  backgroundColor: 'white',
    borderColor: Colors.border,
    borderWidth: 1,
    textAlignVertical: 'top',
    padding: 15,
    flex: .75
  },
  itemStyle: {
    marginBottom: 20
  },
  iconStyle: {
    color: Colors.defaultTextLighter,
    fontSize: 28,
    marginRight: 15
  },
  infoContainer: {
    top: 50,
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'column',
    left: 0
  },
  buttonStyle: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  error: {
    color: Colors.errorBackground,
    paddingVertical: 10
  },
  requirements: {
    color: Colors.defaultTextLighter,
  },
  requirementsHeader: {
    color: Colors.defaultTextLighter,
    fontWeight: 'bold'
  }
})

function objectWithProperties(obj, keys) {
    const target = {};
    for (const key in obj) {
        if (keys.indexOf(key) === -1) {
            continue;
        }
        if (!Object.prototype.hasOwnProperty.call(obj, key)) {
            continue;
        }
        target[key] = obj[key];
    }
    return target;
}
