const config = {
  Auth: {
    userPoolId: "us-east-1_Nt7lKK4U7",
    region: "us-east-1",
    userPoolWebClientId: "3kp8hh3t38ecsv4vk66qtmn0bk",
    identityPoolId: "us-east-1:78409c10-53cf-481d-a178-7d7de2732f60"
  },
  Storage: {
    AWSS3: {
        bucket: 'residencyapp-qrcode-dev', //REQUIRED -  Amazon S3 bucket
        region: 'us-east-1', //OPTIONAL -  Amazon service region
    }
  },
  "aws_project_region": "us-east-1",
  "aws_content_delivery_bucket": "residencyappweb-20190309141341-hostingbucket-dev",
  "aws_content_delivery_bucket_region": "us-east-1",
  "aws_content_delivery_url": "http://residencyappweb-20190309141341-hostingbucket-dev.s3-website-us-east-1.amazonaws.com",
  "aws_cognito_region": "us-east-1",
  "aws_appsync_graphqlEndpoint": "https://2nctzwwkfzcvplssimdxwp77si.appsync-api.us-east-1.amazonaws.com/graphql",
  "aws_appsync_region": "us-east-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "env": "prod"
}

export default config;
