import React from 'react';
import {
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
  TextInput,
  ActivityIndicator
} from 'react-native';
import { OriyaText } from '../components/StyledText';
import { HR } from '../components/HR';
import Colors from '../constants/Colors';
import CustomImage from '../components/CustomImage';
import Layout from '../constants/Layout';
import { Icon } from '../components/Icon';

import Storage from '@aws-amplify/storage';
import Auth from "@aws-amplify/auth";

Storage.configure({ level: 'public' });

export default class QRCodeScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      qr: null,
      loadingQR: true,
      failed: false
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      this.setState({ user: nextProps.user });
    }
  }

  async componentDidMount() {
    try {
      const qr = await Storage.get(this.state.user.username + ".jpg");
      if (qr) {
        this.setState({ qr: qr, loadingQR: false }, () => {
          console.log(qr)
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <View style={styles.container}>
        {
          (this.state.loadingQR) ?
          (
            <View style={[styles.appContainer, styles.loadingContainer]}>
              <OriyaText>Loading...</OriyaText>
              <ActivityIndicator size="large" color={Colors.electricblue} />
            </View>
          ) : null
        }
        {
          (this.state.qr) ?
          (
            <View style={styles.qrContainer}>
              <ScrollView minimumZoomScale={1} maximumZoomScale={2} contentContainerStyle={{alignItems: 'center'}}>
                <CustomImage imageUri={this.state.qr} qr={true} />
              </ScrollView>
            </View>
          ) : null
        }
        {
          (this.state.failed) ?
          (
            <View style={styles.loadingContainer}>
              <OriyaText>Image does not exist</OriyaText>
            </View>
          ) : null
        }
      </View>
    )
  }
}

const styles = StyleSheet.create({
  appContainer: {
    paddingHorizontal: 20,
    flex: 1,
    backgroundColor: '#fff',
    justifyContent: 'flex-start'
  },
  loadingContainer: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  qrContainer: {
    position: 'absolute',
    justifyContent: 'center',
    alignSelf: 'center'
  }
});
