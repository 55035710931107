import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  SafeAreaView,
  StatusBar,
  KeyboardAvoidingView,
  Keyboard,
  Alert,
  TextInput
} from 'react-native';
import Button from '../components/Button';
import TopHeader from '../components/TopHeader';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import { ConfirmSignIn } from 'aws-amplify-react';
// AWS Amplify modular import
import Auth from '@aws-amplify/auth'

// Load the app logo
const logo = require('../assets/images/ResidencyAppIcon.png')

export default class CustomConfirmSignIn extends ConfirmSignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["confirmSignIn"];
    this.state = {
      code: null,
      error: null
    }
  }

  showComponent(theme) {
    return (
      <KeyboardAvoidingView style={styles.container} behavior='padding' enabled>
        <View style={styles.container}>
          <View>
            <TopHeader>
              Confirm Sign In
            </TopHeader>
          </View>
          <View style={styles.contentContainer}>
            <View style={styles.itemStyle}>
              <TextInput
                style={styles.input}
                value={this.state.code}
                placeholder='Enter your confirmation code'
                keyboardType={'default'}
                autoCapitalize='none'
                autoCorrect={false}
                onChangeText={(text) => {console.log(this.state.code); this.setState({ code: text })}}
              />
            </View>
            <Button
              style={styles.buttonStyle}
              text='Confirm'
              onPress={() => {console.log('confirm'); super.confirm()}}
            />
            <Button style={styles.buttonStyle} text='Back to Sign In' onPress={() => this.changeState('signIn')}/>
          </View>
        </View>
      </KeyboardAvoidingView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 10,
    right: 10,
    flex: 1,
    backgroundColor: '#ffffff',
    justifyContent: 'flex-start',
    flexDirection: 'column'
  },
  contentContainer: {
    marginTop: Layout.contentPaddingTopL
  },
  input: {
    fontSize: Layout.pText,
    color: Colors.defaultText,
	  backgroundColor: 'white',
    borderColor: Colors.border,
    borderWidth: 1,
    textAlignVertical: 'top',
    padding: 15,
    flex: .75
  },
  itemStyle: {
    marginBottom: 20
  },
  iconStyle: {
    color: Colors.defaultTextLighter,
    fontSize: 28,
    marginRight: 15
  },
  infoContainer: {
    left: 0,
    top: 50,
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'column',
    left: 0
  },
  buttonStyle: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  logoContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: 300,
    top: 0,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
})
