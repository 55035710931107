import React from 'react';
import './App.css';
import { Linking, Button, StyleSheet, Text, View, Platform, StatusBar, AsyncStorage, Alert } from 'react-native';
import HomeScreen from './screens/HomeScreen';
import CustomSignIn from './screens/CustomSignIn';
import CustomConfirmSignIn from './screens/CustomConfirmSignIn';
import CustomRequireNewPassword from './screens/CustomRequireNewPassword';
import CustomLoading from './screens/CustomLoading';
import { Authenticator, SignIn, Greetings, ConfirmSignIn, RequireNewPassword, ForgotPassword, Loading } from 'aws-amplify-react';
import Auth from '@aws-amplify/auth';
import Amplify, { Storage } from 'aws-amplify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { Logger } from 'aws-amplify';
import * as queries from './graphql/queries';
import * as mutations from './graphql/mutations';
import awsconfig from './aws-configs';

// retrieve temporary AWS credentials and sign requests
Amplify.configure(awsconfig);

/*
Auth:
1) Admin creates user accounts -- user gets a text with their temp password
*/

/*
 * Convo with Cory
   * Separating out wellness resources from others like Schedules, educational resources, calendar
   * Home page which lists the next social event, in addition to separating out resources
*/

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = async () => {
    await Auth.currentAuthenticatedUser()
    .then(user => {
      this.setState({userToken: user.signInUserSession.accessToken.jwtToken})
    })
    .catch(err => {
      console.log("error getting authenticated user")
      console.log(err);
    })
  }

  async signIn() {
    const { username, password } = this.state;
    await Auth.signIn(username, password)
    .then(user => {
      this.setState({ user });
    })
    .catch(err => {
      if (! err.message) {
        console.log('Error when signing in: ', err)
        Alert.alert('Error when signing in: ', err)
      } else {
        console.log('Error when signing in: ', err.message)
        Alert.alert('Error when signing in: ', err.message)
      }
    })
  }

  render() {
    if (this.props.authState == "signedIn") {
      return (
        <View style={styles.container}>
          {Platform.OS === 'ios' && <StatusBar barStyle="default" />}
          <HomeScreen />
        </View>
      );
    } else {
      return null;
    }
  }
}

class AppWithAuth extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <Authenticator hide={[SignIn, ConfirmSignIn, RequireNewPassword, Loading]} hideDefault={true} amplifyConfig={awsconfig}>
        <CustomSignIn/>
        <CustomConfirmSignIn/>
        <ForgotPassword/>
        <CustomRequireNewPassword/>
        <CustomLoading/>
        <App />
      </Authenticator>
    );
  }
}

export default AppWithAuth;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute'
  },
});
