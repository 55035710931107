import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  SafeAreaView,
  StatusBar,
  KeyboardAvoidingView,
  Keyboard,
  Alert,
  Animated,
  TextInput,
  ActivityIndicator
} from 'react-native';
import { Icon } from '../components/Icon';
import { OriyaText } from '../components/StyledText';
import Button from '../components/Button';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import { Loading } from 'aws-amplify-react';

export default class CustomLoading extends Loading {
  constructor(props) {
    super(props);
    this._validAuthStates = ["loading"];
  }

  showComponent(theme) {
    return (
      <View style={[styles.appContainer, styles.loadingContainer]}>
        <OriyaText>Loading...</OriyaText>
        <ActivityIndicator size="large" color={Colors.electricblue} />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  appContainer: {
    paddingTop: 35,
    paddingHorizontal: 20,
    flex: 1,
    backgroundColor: '#fff',
    justifyContent: 'flex-start'
  },
  loadingContainer: {
    alignItems: 'center',
    justifyContent: 'center'
  }
})
