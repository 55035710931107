import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  SafeAreaView,
  StatusBar,
  KeyboardAvoidingView,
  Keyboard,
  Alert,
  Animated,
  TextInput
} from 'react-native';
import { Icon } from '../components/Icon';
import Button from '../components/Button';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import Auth from '@aws-amplify/auth';
import { SignIn } from 'aws-amplify-react';

// Load the app logo
const logo = require('../assets/images/ResidencyAppIcon.png')

export default class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
  }
  state = {
    username: '',
    password: '',
    error: ''
  }

  componentDidMount() {
  }

  async signIn() {
    const { username, password } = this.state;
    if (!Auth || typeof Auth.signIn !== 'function') {
        throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported');
    }
    super.setState({loading: true});
    try {
      const user = await Auth.signIn(username, password);
      if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        // logger.debug('confirm user with ' + user.challengeName);
        super.changeState('confirmSignIn', user);
      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        // logger.debug('require new password', user.challengeParam);
        super.changeState('requireNewPassword', user);
      } else if (user.challengeName === 'MFA_SETUP') {
        // logger.debug('TOTP setup', user.challengeParam);
        super.changeState('TOTPSetup', user);
      } else {
        super.checkContact(user);
      }
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        // logger.debug('the user is not confirmed');
        super.changeState('confirmSignUp', {username});
      } else if (err.code === 'PasswordResetRequiredException') {
        // logger.debug('the user requires a new password');
        super.changeState('forgotPassword', {username});
      } else {
        this.setState({error: err.message})
      }
    } finally {
      this.setState({loading: false})
    }
    }

  showComponent(theme) {
    return (
        <SafeAreaView style={styles.container}>
          <KeyboardAvoidingView style={styles.container} behavior='padding' enabled>
            <View style={styles.logoContainer}>
              <Image
                source={logo}
                style={{ width: 150, height: 150 }}/>
            </View>
            <View style={styles.infoContainer}>
              <View style={styles.itemStyle}>
                <View style={styles.iconStyle}>
                  <Icon
                    active
                    name='user'
                    style={styles.iconStyle}
                  />
                </View>
                <TextInput
                  style={styles.input}
                  value={this.state.username}
                  placeholder='Your Username'
                  keyboardType={'default'}
                  autoCapitalize='none'
                  autoCorrect={false}
                  onChangeText={(text) => {this.setState({ username: text })}}
                />
              </View>
              <View style={styles.itemStyle}>
                <View style={styles.iconStyle}>
                  <Icon
                    active
                    name='lock'
                    />
                  </View>
                <TextInput
                  style={styles.input}
                  value={this.state.password}
                  placeholder='Password'
                  returnKeyType='go'
                  autoCapitalize='none'
                  autoCorrect={false}
                  secureTextEntry={true}
                  ref='SecondInput'
                  onChangeText={(text) => {this.setState({ password: text })}}
                />
              </View>
              <Button
                onPress={(event) => {
                  if (!this.state.password) {
                    this.setState({error: 'Password cannot be empty'});
                    return;
                  }
                  this.signIn(event)}
                }
                style={styles.buttonStyle}
                text="Sign In" />
              {
                this.state.error ?
                (this.state.error.indexOf('null') !== -1) ?
                (
                  <View>
                    <Text style={styles.error}>Unknown error. Please check that you entered the correct information, then contact your administrator.</Text>
                  </View>
                ) :
                (
                  <View>
                    <Text style={styles.error}>{this.state.error}</Text>
                  </View>
                ) : null
              }
            </View>
            <View style={styles.forgotPassword}>
              <Button style={styles.resetPasswordButtonStyle} text="Reset Password" onPress={() => super.changeState('forgotPassword')} />
            </View>
          </KeyboardAvoidingView>
        </SafeAreaView>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10%'
  },
  input: {
    flex: 1,
    fontSize: Layout.pText,
    color: Colors.defaultText,
	  backgroundColor: 'white',
    borderColor: Colors.border,
    borderWidth: 1,
    textAlignVertical: 'top',
    padding: '.5em'
  },
  itemStyle: {
    marginBottom: '2em',
    flexDirection: 'row',
    alignItems: 'center'
  },
  iconStyle: {
    color: Colors.defaultTextLighter,
    fontSize: 28,
    marginRight: '.5em'
  },
  infoContainer: {
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: Layout.contentPaddingTop,
    paddingHorizontal: '1.5em'
  },
  buttonStyle: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  resetPasswordButtonStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff'
  },
  logoContainer: {
    marginTop: 0,
    marginBottom: 0,
    paddingBottom: 0,
    alignItems: 'center',
    justifyContent: 'center'
  },
  error: {
    color: Colors.errorBackground,
    paddingVertical: '1em',
    textAlign: 'center',
    padding: '1em'
  },
  forgotPassword: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    bottom: 10
  },
  forgotPasswordText: {
    color: Colors.pulse
  }
})
