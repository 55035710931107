import React from 'react';
import {
  TouchableOpacity,
  View,
  Text
} from 'react-native';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
// import { Ionicons as Icon } from 'react-web-vector-icons';
import MdArrowBack from 'react-ionicons/lib/MdArrowBack';
import { OriyaText } from '../components/StyledText';


export default class BackButton extends React.Component {
  render() {
    return (
      <TouchableOpacity style={style} onPress={this.props.onPress}>
        <MdArrowBack fontSize="1.5em" color={Colors.defaultText} />
        <Text style={textStyle}>Back to Resources</Text>
      </TouchableOpacity>
    )
  }
}

const style = {
  backgroundColor: Colors.button,
  borderRadius: 100,
  flexDirection: 'row',
  paddingHorizontal: 5,
  width: Layout.buttonWidth,
  height: Layout.buttonHeight,
  alignItems: 'center'
}

const textStyle = {
  color: Colors.defaultText,
  fontSize: 18,
  padding: 10
}
