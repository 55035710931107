import React from 'react';
import {
  TouchableOpacity,
  View,
} from 'react-native';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import { OriyaText } from '../components/StyledText';

export default class TopHeader extends React.Component {
  render() {
    return <OriyaText {...this.props} style={[this.props.style, style]} />;
  }
}

const style = {
  fontSize: Layout.h1Text,
  fontFamily: 'OriyaSangamMN',
  paddingTop: 10,
  color: Colors.header
}
