import React from 'react';
import {
  TouchableOpacity,
  View,
} from 'react-native';
import Colors from '../constants/Colors';
import MdPulse from 'react-ionicons/lib/MdPulse'

export default class Pulse extends React.Component {
  render() {
    return (
      <TouchableOpacity style={style} onPress={this.props.onPress}>
        <MdPulse fontSize="1.7em" color={Colors.pulse} />
      </TouchableOpacity>
    )
  }
}

const style = {
  backgroundColor: Colors.button,
  borderRadius: '10em',
  padding: '.7em',
  height: '100%'
}
